import { createTheme } from "@mui/material";
import { colorSchemes, shadows, spacing, typography } from "./Core";
import "./global.css";
import {
    MuiBreadcrumbs,
    MuiChip,
    MUIDataTable,
    MUIDataTableBodyCell,
    MUIDataTableBodyRow,
    MUIDataTableToolbar,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiDrawer,
    MuiFormControl,
    MuiListItemButton,
    MuiLoadingButton,
    MuiPickers,
    MuiRadio,
    MuiSelect,
    MuiTable,
    MuiTableFooter,
    MuiTabs,
    MuiTextField,
} from "./Components";

const DefaultTheme = createTheme({
    ...colorSchemes.light,
    typography,
    spacingTheme: { ...spacing },
    shadowsTheme: { ...shadows("light") },
    components: {
        MuiDrawer,
        MuiTabs,
        MuiListItemButton,
        MuiLoadingButton,
        MuiFormControl,
        MuiTextField,
        MuiSelect,
        MuiPickers,
        MuiRadio,
        MuiBreadcrumbs,
        MUIDataTable,
        MUIDataTableToolbar,
        MUIDataTableBodyRow,
        MUIDataTableBodyCell,
        MuiTableFooter,
        MuiTable,
        MuiDialog,
        MuiDialogTitle,
        MuiDialogContent,
        MuiDialogActions,
        MuiChip,
    },
});
export default DefaultTheme;
