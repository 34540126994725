import { varAlpha } from "../Core/Utils/utils";

export const MuiLoadingButton = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...colorsByState(ownerState, theme),
            ...sizeByDefinedSize(ownerState.size, theme),
            borderRadius: theme.spacingTheme["1"],
        }),
    },
};

const colorsByState = (state, theme) => {
    if (state.disabled) {
        return {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        };
    }

    switch (state.variant) {
        case "outlined":
            return {
                border: `${theme.palette.globals.inherit.bgcolor} solid 1px`,
                backgroundColor: theme.palette.globals.inherit.color,
                color: theme.palette.globals.inherit.bgcolor,
                "&:hover": {
                    border: `${theme.palette.globals.inherit.bgcolor} solid 1px`,
                    backgroundColor: theme.palette.globals.inherit.bgcolor,
                    color: theme.palette.globals.inherit.color,
                },
            };
        case "solid-primary":
            return {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: varAlpha(theme.palette.primary["mainChannel"], 0.8),
                    color: theme.palette.primary.contrastText,
                },
            };
        case "solid-secondary":
            return {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: varAlpha(theme.palette.secondary["mainChannel"], 0.8),
                    color: theme.palette.secondary.contrastText,
                },
            };
        case "solid-info":
            return {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: varAlpha(theme.palette.info["mainChannel"], 0.8),
                    color: theme.palette.info.contrastText,
                },
            };
        case "solid-success":
            return {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: varAlpha(theme.palette.success["mainChannel"], 0.8),
                    color: theme.palette.success.contrastText,
                },
            };
        case "solid-error":
            return {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: varAlpha(theme.palette.error["mainChannel"], 0.8),
                    color: theme.palette.error.contrastText,
                },
            };
        case "clear":
            return {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.globals.inherit.bgcolor,
                "&:hover": {
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.globals.inherit.bgcolor,
                },
            };
        case "contained-primary":
            return {
                backgroundColor: varAlpha(theme.palette.primary["mainChannel"], 0.08),
                color: theme.palette.primary.dark,
                "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                },
            };
        case "contained-secondary":
            return {
                backgroundColor: varAlpha(theme.palette.secondary["mainChannel"], 0.08),
                color: theme.palette.secondary.dark,
                "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                },
            };
        case "contained-info":
            return {
                backgroundColor: varAlpha(theme.palette.info["mainChannel"], 0.08),
                color: theme.palette.info.dark,
                "&:hover": {
                    backgroundColor: theme.palette.info.dark,
                    color: theme.palette.info.contrastText,
                },
            };
        case "contained-error":
            return {
                backgroundColor: varAlpha(theme.palette.error["mainChannel"], 0.08),
                color: theme.palette.error.main,
                "&:hover": {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                },
            };
        case "contained":
        default:
            return {
                backgroundColor: theme.palette.globals.inherit.bgcolor,
                color: theme.palette.globals.inherit.color,
                "&:hover": {
                    backgroundColor: theme.palette.globals.inherit.hover,
                },
            };
    }
};

const sizeByDefinedSize = (size, theme) => {
    switch (size) {
        case "small":
            return {
                height: "36px",
            };
        default:
            return {
                height: theme.spacingTheme["6"],
            };
    }
};
