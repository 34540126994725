import { cloneJson } from "../Common/CloneCommon";
import moment from "moment";
import axios from "axios";
import { dispatch } from "../App";
import { SnackbarOpen } from "../Reducer/SnackBarReducer/SnackBarSlice";
import { routeSso } from "./RouteApi/SsoApiRoute";
import { routeApp } from "./RouteApi/AppApiRoute";
import { routeBo } from "./RouteApi/BoApiRoute";
import { t } from "i18next";

const routeApi = {
    api_default: "/api/",
    api_logout: "/api/logout",
    ...routeSso,
    ...routeApp,
    ...routeBo,
};

const cacheConfig = {
    /*
        Exemple
        api_select_users: 14400,
    */
};

function ApiHandler() {
    let cache = {};

    this.host = function () {
        return window.location.protocol + "//" + window.location.host;
    };
    this.route = function (options) {
        let route = routeApi[options.route];

        if (!route) {
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: t("snackbar:error.missingRoute"),
                    variant: "error",
                }),
            );
            return;
        }

        if (options.data) {
            route += "?";
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? "" : options.data[index];
                dataGet = dataGet === undefined ? "" : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                } else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp("{" + key + "}", "gi");
            route = route.replace(regEx, options.params[key]);
        }

        return route;
    };
    this.get = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({ status: 404 });
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: t("snackbar:error.missingRoute"),
                    variant: "error",
                }),
            );
            return;
        }

        if (options.data) {
            route += "?";
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? "" : options.data[index];
                dataGet = dataGet === undefined ? "" : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                } else {
                    if (dataGet || dataGet === 0 || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp("{" + key + "}", "gi");
            route = route.replace(regEx, options.params[key]);
        }

        if (cache[route] && cache[route].age.isAfter(moment())) {
            let data = cloneJson(cache[route].data);
            callback(data);
        } else {
            axios
                .get(route, { withCredentials: true })
                .then(function (response) {
                    if (cacheConfig[options.route]) {
                        if (options.route in cacheConfig) {
                            cache[route] = {
                                data: cloneJson(response.data),
                                age: moment().add(cacheConfig[options.route], "s"),
                            };
                            callback(cloneJson(response.data));
                        }
                    } else {
                        callback(response.data);
                    }
                })
                .catch((err) => {
                    console.log(options.route);
                    console.log(route);
                    console.log(err);
                    if (err.response.status === 401) {
                        this.redirectToSso();
                    } else {
                        if (err.response.data.status === 403 && err.response.data.error.message) {
                            dispatch(
                                SnackbarOpen({
                                    text: err.response.data.error.message,
                                    variant: "error",
                                }),
                            );
                        }
                        callback(err.response.data);
                    }
                });
        }
    };
    this.post = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({ status: 404 });
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: t("snackbar:error.missingRoute"),
                    variant: "error",
                }),
            );
            return;
        }

        for (let key in options.params) {
            let regEx = new RegExp("{" + key + "}", "gi");
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append("_json", JSON.stringify(options.data));
            }
        } else {
            data = JSON.stringify(options.data);
        }

        axios
            .post(route, data, { withCredentials: true })
            .then(function (response) {
                callback(response.data);
            })
            .catch((err) => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    this.redirectToSso();
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: "error",
                            }),
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.delete = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp("{" + key + "}", "gi");
            route = route.replace(regEx, options.params[key]);
        }

        axios
            .delete(route, { withCredentials: true })
            .then(function (response) {
                callback(response.data);
            })
            .catch((err) => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    this.redirectToSso();
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: "error",
                            }),
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.postDownload = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp("{" + key + "}", "gi");
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append("_json", JSON.stringify(options.data));
            }
        } else {
            data = JSON.stringify(options.data);
        }

        axios
            .post(route, data, { withCredentials: true, responseType: "blob" })
            .then((response) => new File([response.data], options.filename))
            .then((response) => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({ status: 200 });
            })
            .catch((err) => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    this.redirectToSso();
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: "error",
                            }),
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.getDownload = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({ status: 404 });
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: "404",
                    variant: "error",
                }),
            );
            return;
        }

        if (!options.data) {
            options.data = { download: 1 };
        } else {
            options.data.download = 1;
        }

        route += "?";
        for (let index in options.data) {
            let dataGet = options.data[index] === null ? "" : options.data[index];
            dataGet = dataGet === undefined ? "" : dataGet;

            if (Array.isArray(dataGet)) {
                for (let index1 in dataGet) {
                    route += index + "[]=" + dataGet[index1] + "&";
                }
            } else {
                if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
            }
        }
        route = route.slice(0, -1);

        for (let key in options.params) {
            let regEx = new RegExp("{" + key + "}", "gi");
            route = route.replace(regEx, options.params[key]);
        }

        axios
            .get(route, { withCredentials: true, responseType: "blob" })
            .then((response) => {
                let attachment = response.headers["content-disposition"];
                if (attachment) {
                    let split = attachment.split('"');
                    options.filename = split[1];
                }
                return new File([response.data], options.filename);
            })
            .then((response) => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({ status: 200 });
            })
            .catch((err) => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    this.redirectToSso();
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: "error",
                            }),
                        );
                    }
                    console.log(err.response.data);
                }
            });
    };
    this.clearCache = function (index) {
        delete cache[routeApi[index]];
    };

    this.redirectToSso = function () {
        let ssoUrl = "";
        const hostname = window.location.hostname;

        if (hostname.includes("dev-")) {
            ssoUrl = "https://dev-sso-v2.medicactiv.com/authentication/login";
        } else if (hostname.includes("my-preprod")) {
            ssoUrl = "https://sso-my-preprod.simforhealth.com/authentication/login";
        } else {
            ssoUrl = "https://sso-my.simforhealth.com/authentication/login";
        }

        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = ssoUrl + `?origin=${currentUrl}`;
    };
}

const api = new ApiHandler();

export default api;
